import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tiles-and-collects"
    }}>{`Tiles and Collects`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Once a tasking request has been accepted or is in an active state, you can view the tile and collect information for that tasking request. Tiles are the spatial footprints used to plan imaging area for a tasking request and are representative of the footprints of products that will be collected as part of the tasking request. Collects are the objects containing information about the imaging opportunities and status of imaging for each tile. The information from tiles and collects can be used to better understand the imaging plan and status of imaging for a tasking request, particularly for larger area requests where multiple scenes are required to cover the full area.`}</p>
    <h2 {...{
      "id": "tiles"
    }}>{`Tiles`}</h2>
    <p>{`Tiles are primarily used to represent and visualize the footprints of scenes that will be part of the tasking request. They are geoJSON polygon features with properties that describe the size and area of the tile. Tiles are only available for tasking requests that have reached an `}<inlineCode parentName="p">{`accepted`}</inlineCode>{` or `}<inlineCode parentName="p">{`active`}</inlineCode>{` state. To list all tiles for a tasking request, you can use the list tiles endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/tiles/list/{{taskingrequestId}}
`}</code></pre>
    <p>{`The response will include the `}<inlineCode parentName="p">{`tileId`}</inlineCode>{`, which you will need to associate tile and collect information.`}</p>
    <h2 {...{
      "id": "collects"
    }}>{`Collects`}</h2>
    <p>{`Collects contain properties describing the imaging geometry, radar parameters, and status of imaging for a particular tile. Collects are also only available for tasking requests that have reached an `}<inlineCode parentName="p">{`accepted`}</inlineCode>{` or `}<inlineCode parentName="p">{`active`}</inlineCode>{` state. To list all collects for a tasking request, you can use the collects list endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/collects/list/{{taskingrequestId}}
`}</code></pre>
    <p>{`The response body contains all of the relevant information about each collect:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`tileId`}</inlineCode>{` can be used to associate the collect information with the related tile.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowOpen`}</inlineCode>{` indicates the expected start time of imaging for the collect.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowClose`}</inlineCode>{` indicates the expected end time of imaging for the collect.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accessProperties`}</inlineCode>{` will include information about the imaging geometry for the collect.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectProperties`}</inlineCode>{` will include information about the radar operating parameters.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectStatusHistory`}</inlineCode>{` will include information about the current and historical status of imaging and product processing for the collect.`}</li>
    </ul>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on tiles and collects, see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/tasking"
      }}>{`Tasking`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      